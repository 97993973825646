const FaqPage = {
  heading: 'Frequently Asked Questions',
  questions: {
    list: [
      {
        question: 'What is CodeMyMobile’s development process?',
        answer:
          'We begin each project with our Business Analysts understanding your requirements and laying out Functional Spec. During this phase, we determine the technologies and development schedule. Once the requirements are clear, we bring in our UI designers to sketch/wireframes/design app screens. We begin with simple wireframes and black & white mockups to illustrate how a user will move through the app (UX). Next, we transition to full color illustrations/designs that have the final look and feel of the app (UI). Then comes the development phase. The development/engineering team works hand-in-hand with the Project Managers and follows the wireframes and the Functional Spec to build the app. Our first development goal is the First Deliverable milestone; this is a highly functional build of the app that contains a subset of features agreed upon by CodeMyMobile and the client. A project is generally split into 2-3 milestones, based on the subset of features. We also provide support and maintenance based on the terms agreed upon on during the contract.',
      },
      {
        question: 'How is CodeMyMobile different from its competitors?',
        answer:
          'CodeMyMobile believes in delivering only the best and that sells. We have a pool of highly talented engineers who can make your dream app possible. Our Dedicated project managers will work with you to bridge our team with yours, streamlining communication between us and eliminating any distortions.',
      },
      {
        question:
          'How long does it take to complete an average app and how much does it cost?',
        answer:
          'With our experience you’re going to find that you’re going to need at least $2000 to build the first version of your product. If we were in full-swing on a new project, a client can estimate a cost of between $1,000 and $2,500 per week ($4,000 to $10,000 per month) for the effort—typically billed bi-monthly. A full version application with a wide set of features and that would include designing as well, can reach upto $80000. Therefore, it largely depends on the complexity of the design and development required. At average, a basic can take from 1 month three months. A full fledged app can take from 3 months to six months. An enterprise app with very high level of complexity can also take upto a year.',
      },
      {
        question: 'What does CodeMyMobile expect from a client to get started?',
        answer:
          'We do expect our clients to be usefully specific about their requirements. The requirements may include outline of features, wireframes, mock-ups, design assets, examples of similar pre-existing apps with similar functionality, clarity about platforms, integrations etc. The more specific your requirements are, the better estimations we can make and the better will be the design phase. If the project involves working with pre-existing software or partnering with an internal development team, then the most pressing item would be any technical documentation, including API documentation and access to a development environment/code base.',
      },
      {
        question:
          'Can you introduce your team? Can the clients interact directly with your engineers?',
        answer:
          'Our team is comprised of 15 individuals across its Design, Development, Project Management, Quality Assurance, Sales and Marketing teams. The majority of our Design and Development team is based in India. Some of our business analysts and project managers operate from New York. Clients can directly interact with the one-point contact - the PMs. Yes, you can directly contact any of our resources via the PM assigned to you.',
      },
      {
        question: 'On what basis do you estimate the cost of a project?',
        answer:
          'Projects are priced on both “time-and-material” and “fixed-price” basis. We do realistic estimations and make sure we give you a clear picture of how much your project would cost if you outsource it to us. We also provide “fixed-price” quote for projects that have concrete and specific requirements set',
      },
      {
        question:
          'Do you guarantee that our app will be published in an app store?',
        answer:
          'We have been very successful when it comes to being accepted by the app stores. However, no application can be guaranteed for app store approval. Our expertise and experience makes us diligently understand what the stores will typically approve or disapprove of. Apple is very selective as to what apps it allows on its store. This strict selective process can hurt us who work hard on apps only to be rejected! However, we have been very careful when submitting applications on the app store. In a few instances in the past, we resolved the rejection by patiently following the corrective actions.',
      },
      {
        question:
          'What is CodeMyMobile’s quality assurance (QA) and testing methodology?',
        answer: '',
      },
      {
        question:
          'I do not know how to place my app in the Android Market and the Apple App Store. How would I do that?',
        answer:
          'Publishing your app in these app stores is pretty simple. All you have to do is create a developer account with Google and Apple through their website. Both companies charge an annual app store fee of $25 and $99, respectively. We will take care of deploying your apps on the app stores.',
      },
      {
        question:
          'Can you describe your expertise in mobile and web app development?',
        answer:
          'Our love for next-generation design patterns, responsive coding techniques, and rigorous quality assurance translates directly into high-quality apps that your audience actually downloads and use. Our team delivers powerful and effective solutions with high-ROI.\n Our customized engagement models help us work with you the way it suits you best. Our services are scalable, so we can address the needs of businesses of all sizes. Companies ranging from innovative startups to well established companies with eight-digit revenue make use of our technical expertise. ',
      },
      {
        question:
          'Do you transfer the Intellectual property rights to the client?',
        answer:
          'Yes. At the end of the project, we will transfer all source code and project files to you and you will remain the sole owner of all IP in the app.',
      },
      {
        question: 'WHAT is an MVP?',
        answer:
          'In app development, the minimum viable product (MVP) is a product with just enough features to gather validated learning about the product and its continued development. Gathering insights from an MVP is often less expensive than developing a product with more features, which increase costs and risk if the product fails, for example, due to incorrect assumptions. Our technical and market experts brainstorm your ideas to split your potential yet vague ideas into MVPs. We then plan pilot releases for such concepts and help you hit the market with minimum risk.',
      },
      {
        question: 'How do I pick a platform?',
        answer:
          'In an ideal world, with infinite resources, you’d launch on both Android and iOS at the same time with a fully featured native app. In reality, you’ll have to consider which platform to launch on first, and each has their own set of pros and cons. Android is currently the market leader in terms of adoption and retention, but iOS apps are more profitable, so if your monetization strategy is dependent on paid-for apps or in-app purchases, then iOS might be your best bet. Aside from that, there are a number of development considerations to take into account before choosing a platform:',
      },
      {
        question: 'Do you provide app marketing services as well?',
        answer:
          'We understand how crucial it is to have the right Go-to-market strategy. We have preliminary marketing services designed for our curated list of clients. We analyze your competitive landscape, get detailed insight on the mobile app ecosystem and understand the role of your app in the global markets. We determine key markets and categories suitable for your app and understand the best time, place and context to launch your mobile app. Our marketers help you transform insights and research data into actionable marketing tactics.',
      },
      {
        question: 'ULTIMATELY, What is the mantra for the success of any app?',
        answer:
          'Success of your doesn’t solely depend on your app idea, but it would very much depend on its beta test and first pilot launch feedback. So, before judging on your idea too much, we recommend you take one step at a time. Get started on your boat, and start sailing. Over the time, with continuous releases and reverse product planning, you would do great. If you have an app idea, you want to discuss with our experienced analysts, drop us a line.',
      },
    ],
  },
  navigation: {
    prev: {
      title: 'Technology',
      overview:
        'From basics to path-breaking technologies, we hack all of them with sheer precision and excellence. We work on varied platforms and disruptive technologies',
    },
    next: {
      title: 'Industries',
      overview:
        'There is phenomenal digital transformation and emergence of automation in all spheres of business. We have worked across a vast range of industries and cater to the finest of technical expertise required for your business needs.',
    },
  },
};

export default FaqPage;
