import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { Layout } from 'layouts';
import {
  PageHeader,
  HexagonImage,
  TechnologyPartners,
  BrandsWorkedWithUs,
} from '@components';
import headerImage from '@static/header/codemymobile_website_2_blue_white_Faq.jpg';
import headingIcon from '@static/icons/Industries_heading_icon.svg';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/faq';

const FaqWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  counter-reset: li;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 90%;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
`;

const FaqQuestionWrapper = styled.div`
  display: flex;
  max-width: 1000px;
  margin: 25px 0;
  position: relative;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Counter = styled.div`
  font-size: 80px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  padding-right: 50px;
  width: 100px;
  min-width: 100px;
  max-width: 100px;

  &:before {
    counter-increment: li;
    content: counter(li, decimal-leading-zero);
    color: ${props => props.theme.colors.primary.base};
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0px;
  }
`;

const QuestionWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Question = styled.h2`
  font-size: 28px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  line-height: 40px;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    text-align: center;
    margin-bottom: 20px;
  }
`;

const Answer = styled.div`
  font-size: 14px;
  line-height: 26px;
  color: ${props => props.theme.colors.black.base};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    text-align: center;
  }
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 26px;
  color: ${props => props.theme.colors.black.base};
  margin-bottom: 5px;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    text-align: center;
  }
`;

const ExploreButton = styled.a`
  font-size: 13px;
  color: ${props => props.theme.colors.primary.base};

  &:hover {
    color: ${props => props.theme.colors.primary.base};
  }
`;

class Faq extends Component {
  textVisibility() {
    alert('hey');
  }
  render() {
    return (
      <Layout>
        <PageHeader image={headerImage} />
        <Heading title={pageContent.heading} />

        {this.renderPageFaq()}

        <PageNavigation
          prev={pageContent.navigation.prev}
          next={pageContent.navigation.next}
        />

        <TechnologyPartners />

        <BrandsWorkedWithUs />
      </Layout>
    );
  }

  renderPageFaq() {
    return (
      <FaqWrapper>
        {pageContent.questions.list.map((item, idx) => {
          let answers = item.answer.split('\n');
          return (
            <FaqQuestionWrapper key={idx}>
              <Counter></Counter>
              <QuestionWrapper>
                <Question>{item.question}</Question>
                <Answer>
                  {answers.map((answer, idx) => (
                    <Text key={idx}>{answer}</Text>
                  ))}
                </Answer>
                <ExploreButton onClick={() => this.textVisibility()}>
                  Read More
                </ExploreButton>
              </QuestionWrapper>
            </FaqQuestionWrapper>
          );
        })}
      </FaqWrapper>
    );
  }
}

export default Faq;
